import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LoginAction } from "../../Store/Login-Slice";
import { cameraTypes, q1List, q2List, q5List, GenresList, HowYouHeardAboutBigPhotoList } from "../../utils/enums/listing";
import { enc, AES } from 'crypto-js';
import { ModalAction } from "../../Store/Modal-Slice";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import SocialMediaButtons from "./SocialMediaButtons";
import { SocialMediaAction } from "../../Store/SocialMedia-Slice";
import amplitude from "amplitude-js"



const JoinNowModal = (props) => {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [FacebookUserEmailValue, setFacebookUserEmailValue] = useState('');
  const [checkingEmailIsempty, setcheckingEmailIsempty] = useState(false);
  const [enteredFullname, setEnteredFullname] = useState();
  const [FullnameIsValid, setFullnameIsValid] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState();
  const [bothPassAndFullNameIsValid, setbothPassAndFullNameIsValid] = useState();
  const [HowYouHeardAboutBigPhotoValue, setHowYouHeardAboutBigPhotoValue] = useState('');
  const [WhatwasItstate, setWhatwasItstate] = useState(false);
  const [WhatwasItValue, setWhatwasItValue] = useState('');
  const [enteredQ1, setEnteredQ1] = useState('');
  const [enteredQ2, setEnteredQ2] = useState('');
  const [enteredQ3, setEnteredQ3] = useState([]);
  const [enteredQ4, setEnteredQ4] = useState('');
  const [enteredQ5, setEnteredQ5] = useState('');
  const [skipModalState, setskipModalState] = useState(false);
  const [skipModalOpen, setskipModalOpen] = useState(false);
  let [spinner, setspinner] = useState(false)
  let [error, seterror] = useState(false)
  const [address, setAddress] = useState('');
  const [validAddress, setvalidAddress] = useState(true);
  const [selectAdress, setselectAdress] = useState(false);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [passwordTouchState, setpasswordTouchState] = useState(false);


  let navigate = useNavigate();
  let JoinbackbtnState = useSelector(state => state.Modal.JoinModalBackbtn)
  let UserSocialInfo = useSelector(state => state.SocialMedia.SocialMediaUserInfo)
  let joinModalHeading = useSelector(state => state.Modal.PopupModalMessage.joinModalHeading)
  let FacebookEmailState = useSelector(state => state.Modal.FacebookEmailState)
  let dispatch = useDispatch()


  function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement("script");
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }

  let JoinNowCloseHandler = () => {
    dispatch(ModalAction.setJoinNowModalState(false))
    dispatch(ModalAction.setJoinModalBackbtn(false))
    dispatch(SocialMediaAction.getSocialMediaUserInfo([]))
    dispatch(ModalAction.setFacebookEmailState(false))
  }

  let emailChangeHandler = (e) => {
    let value = e.target.value;
    setEnteredEmail(value)
    // setEmailIsValid(e.target.value.includes('@'));
    setcheckingEmailIsempty(false)
  }

  let passwordTouchedHandler = () => {
    if (enteredFullname) {
      setpasswordTouchState(true)
      let nameValue = enteredFullname.split(' ')
      setFullnameIsValid(nameValue.length > 1 && !nameValue.includes('') && enteredFullname.includes(' ') && !enteredFullname.includes('@'));
    }

  };

  let RecaptchaTouchedHandler = () => {
    setpasswordTouchState(true)
    setPasswordIsValid(enteredPassword.length > 7);

    let nameValue = enteredFullname.split(' ')
    setFullnameIsValid(nameValue.length > 1 && !nameValue.includes('') && enteredFullname.includes(' ') && !enteredFullname.includes('@'));

    setbothPassAndFullNameIsValid(enteredFullname.length > 1 && enteredFullname.includes(' ') && !enteredFullname.includes('@') && enteredPassword.length > 7)
  }

  let FullnameChangeHandler = (e) => {
    let value = e.target.value;
    setEnteredFullname(value)
    let nameValue = value.split(' ')
    if (passwordTouchState) {
      setFullnameIsValid(nameValue.length > 1 && !nameValue.includes('') && value.includes(' ') && !value.includes('@'));
    }

    setbothPassAndFullNameIsValid(nameValue.length > 1 && !nameValue.includes('') && value.includes(' ') && !value.includes('@') && enteredPassword.length > 7)
  }

  let passwordChangeHandler = (e) => {
    let value = e.target.value;
    setEnteredPassword(value)
    setPasswordIsValid(value.length > 7);
    setbothPassAndFullNameIsValid(value.length > 7 && enteredFullname.length > 1 && enteredFullname.includes(' ') && !enteredFullname.includes('@'))

    if (enteredFullname) {
      setpasswordTouchState(true)
      let nameValue = enteredFullname.split(' ')
      setFullnameIsValid(nameValue.length > 1 && !nameValue.includes('') && enteredFullname.includes(' ') && !enteredFullname.includes('@'));
    }
  }

  // ......................Location .................
  const handleChange = (newAddress) => {
    setAddress(newAddress);
    setselectAdress(!newAddress ? false : "")

  };

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      setselectAdress(true)
      const results = await geocodeByAddress(selectedAddress);

    } catch (error) {
      console.error('Error while geocoding:', error);
    }
  };
  // ......................
  let HowYouHeardAboutBigPhotoHandler = (index) => {

    let value = HowYouHeardAboutBigPhotoList[index].value

    if (value == "Something Else") {
      setWhatwasItstate(true)
      setHowYouHeardAboutBigPhotoValue('')
    } else {
      setHowYouHeardAboutBigPhotoValue(value)
      setWhatwasItstate(false)
      setWhatwasItValue('')
    }
  }

  let HowYouHeardAboutBigPhotoOnChangeHandler = (e) => {
    let value = e.target.value.trim()
    setWhatwasItValue(value)
  }



  let Q1handler = (index) => {
    let value = q1List[index].value;
    setEnteredQ1(value)
    if (q1List[index].value === "I just like to look" || q1List[index].value === "I'm only here supporting a friend") {
      setskipModalState(true)
    } else {
      setskipModalState(false)

    }
  }

  let Q2handler = (index) => {
    let value = q2List[index].value;
    setEnteredQ2(value)
  }




  let Q3handler = (e) => {
    let value = e.target.value
    let checked = e.target.checked
    if (checked) {
      setEnteredQ3([...enteredQ3, value])
    } else {
      setEnteredQ3(enteredQ3.filter((e) => (e !== value)))
    }

  }




  let Q4handler = (index) => {
    let value = cameraTypes[index].value
    setEnteredQ4(value)
  }

  let Q5handler = (index) => {
    let value = q5List[index].value
    setEnteredQ5(value)
  }





  ////////////recaptcha funtion

  function onChange(value) {

    setIsCaptchaChecked(true);
    RecaptchaTouchedHandler()
  }



  let CheckingUserExistHandler = async (event) => {
    event.preventDefault();
    let Email = enteredEmail.toLowerCase().trim();
    let userEmail = Email;
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!Email) {
      setcheckingEmailIsempty(true)
      return
    } else if (!emailPattern.test(Email)) {
      // alert('Please enter your valid email')

      setEmailIsValid(true);

      return
    }

    setspinner(true)
    try {
      const payload = {
        "email": userEmail,
      };

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/user`, payload);

      seterror("This user already exists. Please sign in below.")


      setspinner(false)
    } catch (error) {
      setspinner(false)

      if (error.response.data.message == "We don't recognize that username or password. Email us at") {
        if (FacebookEmailState) {
          let joinAlldiv = document.querySelectorAll(".wizardStep")
          let joinstep1 = document.querySelector(".step-1")
          joinAlldiv.forEach((div) => {
            div.classList.remove("show")
          })
          joinstep1.classList.add("show")
        } else {
          let joinAlldiv = document.querySelectorAll(".wizardStep")
          let join_step_moda = document.querySelector(".join_step_modal")
          joinAlldiv.forEach((div) => {
            div.classList.remove("show")
          })
          join_step_moda.classList.add("show")
          seterror('')
        }

      } else {
        seterror(error.response.data.message)
      }

    }

  }







  let JoinHandler = async (event) => {
    event.preventDefault();





    let Email = enteredEmail.toLowerCase().trim();
    let Fullname = enteredFullname
    let Password = enteredPassword.trim();
    let Q1 = enteredQ1;
    let Q2 = enteredQ2;
    let Q3Array = enteredQ3;
    let Q3myString = Q3Array.join(',');
    let Q4 = enteredQ4;
    let Q5 = enteredQ5;



    let userFullName = UserSocialInfo[2] || Fullname
    let userEmail = UserSocialInfo[1] || Email;
    let lowercaseEmail = userEmail.toLowerCase()
    let userEmailType = UserSocialInfo[0] || "email";
    let userPassword = Password || '';
    let userPicture = UserSocialInfo[3];
    let userQ1Answer = Q1;
    let userQ2Answer = Q2;
    let userQ3Answer = Q3myString;
    let userQ4Answer = Q4;
    let userQ5Answer = Q5;
    let facebookUserId = UserSocialInfo[4] || ''


    // console.log(userFullName, "Email", lowercaseEmail, userEmailType, userPassword, userQ1Answer, userQ2Answer, userQ3Answer, userQ4Answer, userQ5Answer, userPicture, address, "facebookUserId", facebookUserId)

    let getSource = localStorage.getItem("source");
    setspinner(true)
    try {
      let payload;
      if (!skipModalState) {
        payload = {
          name: userFullName.substring(0, 25).trim(),
          email: lowercaseEmail,
          userName: lowercaseEmail,
          password: userPassword,
          photo_url: userPicture || "",
          source: getSource || "",
          userType: userEmailType,
          facebookUserId: facebookUserId,
          photograperType: userQ1Answer,
          photoDesireRatio: Number(userQ2Answer),
          genresList: userQ3Answer,
          cameraBrand: userQ4Answer,
          photo_assistance: userQ5Answer,
          heard_us: WhatwasItValue ? WhatwasItValue : HowYouHeardAboutBigPhotoValue,
          isEmailVerified: false,
          isProvidedDetailedInfo: false,
          isRegistered: false,
          facebookLink: "",
          twitterLink: "",
          instagramLink: "",
          website: "",
          state: address,
          country: "",
        };
      } else {
        payload = {
          name: userFullName.substring(0, 25).trim(),
          email: lowercaseEmail,
          photo_url: userPicture || "",
          userName: lowercaseEmail,
          password: userPassword,
          userType: userEmailType,
          facebookUserId: facebookUserId,
          photograperType: userQ1Answer,
          state: address,
          heard_us: WhatwasItValue || HowYouHeardAboutBigPhotoValue,
          facebookLink: "",
          twitterLink: "",
          instagramLink: "",

        };
      }


      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/registerUser`, payload);


      let token = response.data.data[1].token;
      let user_type = response.data.data[0].user_type;
      setspinner(false)

      if (token) {
        const userData = {
          userid: response.data.data[0].id,
          useDisplay_name: response.data.data[0].name,
          user_email: response.data.data[0].email,
          user_type: user_type,
          token: token,
          Display_UserSocailPhoto: response.data.data[0].photo_url,
          CreatedAt: response.data.data[0].createdAt,
          isLoggedIn: true,
          state: response.data.data[0].state
        };
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
        localStorage.setItem('Data', encryptedUserData);

        dispatch(LoginAction.setIsLoggedIn(true))
        dispatch(ModalAction.setPopupModalState(true))
        if (!localStorage.getItem("source")) {
          navigate('/', { replace: true })
        }
        window.scrollTo(0, 0);



        dispatch(ModalAction.setJoinNowModalState(false))
        dispatch(SocialMediaAction.getSocialMediaUserInfo([]))
        dispatch(ModalAction.setJoinModalBackbtn(false))


        amplitude.getInstance().setUserId(response.data.data[0].id);
        amplitude.getInstance().logEvent(`New Registration`, { UserID: response.data.data[0].id });
        amplitude.getInstance().logEvent(`${user_type.charAt(0).toUpperCase() + user_type.slice(1)} Registration`, { UserID: response.data.data[0].id });

      }

    } catch (error) {


      setspinner(false)
    }



  }

  return (
    <>
      <div className="backdrop" />
      {!skipModalOpen ? <form onSubmit={JoinHandler} noValidate>
        <div
          className="modal fade bph-modal show "
          id="joinModal"
          tabIndex={-1}
          aria-labelledby="joinModal"
          aria-hidden="true"
          style={{ display: 'block' }}
        >
          <div className="modal-dialog modal-dialog-centered modalAnimation">
            <div className="modal-content">
              <div className="modal-body">
                {/* <!-- join --> */}
                {!JoinbackbtnState && !FacebookEmailState && <div className="onboarding_modal wizardStep show join_modal">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close joinModalClose"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={JoinNowCloseHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Join the club {joinModalHeading} </h4>
                      </div>

                      {/* <!-- log reg button --> */}

                      <SocialMediaButtons />

                      {/* <!-- divider --> */}
                      <div className="divider">
                        <span>or via email</span>
                      </div>

                      {/*------------------ alert------------- */}




                      {/* <!-- form --> */}
                      {error && <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>}
                      <div className="form">
                        <div action="#" className="join_form">
                          <div className="form_group">
                            <input
                              type="email"
                              // name="email"
                              id="email"
                              placeholder="Email"
                              value={enteredEmail}
                              onChange={emailChangeHandler}
                            // onBlur={validateEmailHandler}

                            />
                            <div className="form_error"></div>
                          </div>
                          {emailIsValid ? <p className="eerror">Please enter your valid email (example@mail.com)</p> : ''}
                          {checkingEmailIsempty ? <p className="eerror">Please enter your email</p> : ''}

                          <div className="buttons">


                            {!spinner ? <button

                              className="btn "
                              type="button"
                              onClick={CheckingUserExistHandler}
                            >
                              Join
                            </button> : <button className="btn" type="button" >
                              <span className="loader"></span>
                            </button>}
                          </div>
                        </div>
                      </div>

                      {/* <!-- privacy text --> */}
                      <div className="privacy_text">
                        <p>
                          We will never post to any of your accounts without your
                          permission
                        </p>
                      </div>

                      {/* <!-- have account --> */}
                      <div className="have_account">
                        <p>
                          Have an account?
                          <Link
                            onClick={() => {
                              dispatch(ModalAction.setJoinNowModalState(false))
                              dispatch(ModalAction.setSignInModalState(true))
                            }}
                            style={{ marginLeft: "5px" }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#signInModal"
                          >
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>}





                {/* <!-- join step --> */}
                <div className="onboarding_modal wizardStep join_step_modal  ">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={JoinNowCloseHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Join the club {joinModalHeading}</h4>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="join_step_form">
                          <div className="form_group">
                            <label htmlFor="fullname">Full Name</label>
                            <input type="text" id="fullname"
                              placeholder="Enter first and last name"
                              maxLength={25}
                              value={enteredFullname}
                              onChange={FullnameChangeHandler} />
                            <div className="form_error"></div>
                          </div>
                          {FullnameIsValid === false ? <p className="eerror">Please enter a first and last name (surname).</p> : ''}

                          <div className="form_group">
                            <label htmlFor="password">Choose a Password</label>
                            <input
                              onClick={passwordTouchedHandler}
                              type="password"
                              name="password"
                              id="password"
                              value={enteredPassword}
                              onChange={passwordChangeHandler}
                            // onBlur={validatePasswordHandler}
                            />
                            <div className="form_error"></div>
                          </div>
                          {passwordIsValid === false ? <p className="eerror">Password must be at least 8 characters</p> : ''}
                          <div className="form_group captcha">


                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                              onChange={onChange}
                            />


                            {/* <img src="./assets/img/captcha/captcha.png" alt="img" /> */}
                          </div>

                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button
                              type="button"
                              className="btn nextStep"

                              disabled={!bothPassAndFullNameIsValid || !isCaptchaChecked}

                            >
                              Get started
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <!-- privacy text --> */}
                      <div className="privacy_text">
                        <p>
                          We will never post to any of your accounts without your
                          permission
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- ========= how you heard about the big photo  == --> */}
                <div className={`onboarding_modal wizardStep ques_step_modal cool-step-modal ${JoinbackbtnState ? "show" : ""}`}>
                  <div className="modal_inner">
                    {/* data-bs-dismiss="modal" */}
                    <button type="button" className="btn-close" aria-label="Close" onClick={JoinNowCloseHandler} />
                    {/* logo */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt />
                    </div>
                    {/* content */}
                    <div className="modal_content text-start">
                      <div className="modal_title">
                        <h4 className="title">Before we<br /> push the go button...</h4>
                      </div>
                      {/* form */}
                      <div className="form show-box">

                        <div className="form_title">
                          <p>
                            Mind telling us how you heard about the
                            Big Photo Hunt?</p>
                        </div>
                        {HowYouHeardAboutBigPhotoList.map((item, index) => (
                          <div className="form_group radio" key={index}>
                            <input type="radio" value={item.value} name="photoType" id={item.id} onChange={() => HowYouHeardAboutBigPhotoHandler(index)} />
                            <label htmlFor={item.id}>{item.value}</label>
                          </div>
                        ))}

                        <div className="else-box ">

                          {WhatwasItstate && <input maxLength={100} type="text" name="name" placeholder="Oh, cool! What was it?" value={WhatwasItValue} onChange={HowYouHeardAboutBigPhotoOnChangeHandler} />}
                        </div>
                        <div className="buttons">
                          <button type="button" className="btn btn-edit previousStep" disabled={JoinbackbtnState}>Back</button>
                          <button type="button" className="btn nextStep"
                            disabled={HowYouHeardAboutBigPhotoValue ? !HowYouHeardAboutBigPhotoValue : !WhatwasItValue.length > 0}>Next</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                {/* facebook User not have email */}

                {FacebookEmailState && <div className={`onboarding_modal wizardStep join_modal ${FacebookEmailState ? "show" : ''} }`}>
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close joinModalClose"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={JoinNowCloseHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Join the club {joinModalHeading} </h4>
                      </div>
                      <p style={{ color: "black", fontSize: "1rem" }}>Welcome!</p>
                      <p style={{ color: "black", fontSize: "1rem" }}>Please add your email address to continue</p>
                      {/* <!-- form --> */}
                      {error && <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>}
                      <div className="form">
                        <div action="#" className="join_form">
                          <div className="form_group">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email"
                              value={enteredEmail}
                              onChange={emailChangeHandler}
                              required
                            // onBlur={validateEmailHandler}

                            />
                            <div className="form_error"></div>
                          </div>
                          {emailIsValid ? <p className="eerror">Please enter your valid email (example@mail.com)</p> : ''}
                          {checkingEmailIsempty ? <p className="eerror">Please enter your email</p> : ''}

                          <div className="buttons">

                            {!spinner ? <button

                              className="btn "
                              type="button"
                              onClick={CheckingUserExistHandler}
                            >
                              Continue
                            </button> : <button className="btn" type="button" >
                              <span className="loader"></span>
                            </button>}



                          </div>
                        </div>
                      </div>
                      <div className="have_account">
                        <p style={{ marginBottom: "30px" }}>
                          Have an account?
                          <Link
                            onClick={() => {
                              JoinNowCloseHandler()
                              dispatch(ModalAction.setSignInModalState(true))
                            }}
                            style={{ marginLeft: "5px" }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#signInModal"
                          >
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>}

                {/* <!-- question step 1 --> */}
                <div className={`onboarding_modal wizardStep ques_step_modal step-1 `}>
                  <div className="modal_inner">
                    {/* <!-- data-bs-dismiss="modal" --> */}
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-toggle="modal"
                      onClick={JoinNowCloseHandler}
                    // data-bs-target="#skipModal"
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">
                          So we can tailor your experience for you.
                        </p>
                        <div className="count">
                          <span>
                            <span className="active">1</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div className="ques_step_form">
                          <div className="form_title">
                            <p>
                              Which type of photographer do you consider yourself?
                            </p>
                          </div>
                          {/* <div className="form_group radio">
                            <input type="radio" name="photoType" id="type5" value="I just like to look" onChange={Q1handler} />
                            <label htmlFor="type5">I just like to look</label>
                          </div> */}

                          {q1List.map((item, index) => (
                            <div className="form_group radio" key={index}>
                              <input type="radio" name='photoType' id={item.value} value={item.value} onChange={() => Q1handler(index)} />
                              <label htmlFor={item.value}>{item.value}</label>
                            </div>
                          ))

                          }


                          <div className="buttons">
                            <button
                              className="btn btn-edit previousStep"

                              // disabled={Ctx.modalbackbtnstate}


                              type="button"
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              className={`btn ${!skipModalState ? "nextStep" : ""}`}
                              onClick={skipModalState
                                ? () => {
                                  document.querySelector(".step-2").classList.remove("show");
                                  document.querySelector(".step-6").classList.add("show");
                                }
                                : undefined
                              }

                              disabled={!enteredQ1}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- question step 2 --> */}
                <div className="onboarding_modal wizardStep ques_step_modal step-2">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                      onClick={JoinNowCloseHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">
                          So we can tailor your experience for you.
                        </p>
                        <div className="count">
                          <span>
                            <span className="active">2</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-2">
                          <div className="form_title">
                            <p>
                              How often do you get that burning itch to take photos?
                            </p>
                            <span>Not all often</span>
                          </div>
                          <div className="radios">

                            {q2List.map((item, index) => (
                              <div className="form_group radio" key={index}>
                                <input type="radio" name='take' id={item.value} value={item.value} onChange={() => Q2handler(index)} />
                                <label htmlFor={item.value}>{item.value}</label>
                              </div>
                            ))

                            }


                          </div>
                          <div className="form_title">
                            <span>Extremely often</span>
                          </div>

                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button
                              type="button"
                              className="btn nextStep"

                              disabled={!enteredQ2}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- question step 3 --> */}
                <div className="onboarding_modal wizardStep ques_step_modal step-3">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                      onClick={JoinNowCloseHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">
                          So we can tailor your experience for you.
                        </p>
                        <div className="count">
                          <span>
                            <span className="active">3</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-2">
                          <div className="form_title">
                            <p>What are your favorite genres to shoot?</p>
                          </div>
                          <div className="checkboxes">

                            {GenresList.map((item, index) => (
                              <div className="col-6" key={index}>
                                <div className="form_group checkbox">
                                  <input className='check' type="checkbox"
                                    name={item.value} value={item.value} id={item.value} onChange={Q3handler} />
                                  <label htmlFor={item.value}>{item.value}</label>
                                </div>
                              </div>
                            ))
                            }

                          </div>
                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button
                              type="button"
                              className="btn nextStep"

                              disabled={!enteredQ3.length > 0}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- question step 4 --> */}
                <div className="onboarding_modal wizardStep ques_step_modal step-4">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                      onClick={JoinNowCloseHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">Only one more after this one.</p>
                        <div className="count">
                          <span>
                            <span className="active">4</span> / 5
                          </span>
                        </div>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-4">
                          <div className="form_title">
                            <p>Which camera brand do you prefer?</p>
                          </div>
                          <div className="radios">


                            {cameraTypes.map((item, index) => (
                              <div className="form_group radio" key={index}>
                                <input type="radio" name='camera' id={item.value} value={item.value} onChange={() => Q4handler(index)} />
                                <label htmlFor={item.value}>{item.displayText}</label>
                              </div>
                            ))

                            }

                          </div>

                          <div className="buttons">
                            <Link
                              className="btn btn-edit previousStep"

                            >
                              Back
                            </Link>
                            <button className="btn nextStep" type="button" data-bs-dismiss="modalll" disabled={!enteredQ4}  >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                {/* question step 5 */}
                <div className="onboarding_modal wizardStep ques_step_modal step-5">
                  <div className="modal_inner">
                    <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" onClick={JoinNowCloseHandler} />
                    {/* logo */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>
                    {/* content */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Only 5 quick questions</h4>
                        <p className="subtitle">Last one, then you're in!</p>
                        <div className="count">
                          <span><span className="active">5</span> / 5</span>
                        </div>
                      </div>
                      {/* form */}
                      <div className="form">
                        <div action="#" className="ques_step_form step-5">
                          <div className="form_title">
                            <p>Which of the following is the most important thing that the Big Photo Hunt could help with?</p>
                          </div>
                          <div className="radios">

                            {q5List.map((item, index) => (
                              <div className="form_group radio" key={index}>
                                <input type="radio" name='help' id={item.value} value={item.value} onChange={() => Q5handler(index)} />
                                <label htmlFor={item.value}>{item.value}</label>
                              </div>
                            ))
                            }

                          </div>
                          <div className="buttons">
                            <a className="btn btn-edit previousStep" >Back</a>
                            {/* {!spinner ? */}
                            <button className="btn nextStep" type="button" disabled={!enteredQ5}>Next</button>
                            {/* :
                              <button className="btn">
                                <span className="loader"></span>
                              </button>
                            } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                {/* user location modal */}
                <div className="onboarding_modal wizardStep ques_step_modal step-6">
                  <div className="modal_inner">
                    <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" onClick={JoinNowCloseHandler} />
                    {/* logo */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>
                    {/* content */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Oh, we need to know where you are!</h4>
                        <p className="subtitle">Where in the world do you live?!</p>
                      </div>
                      {/* form */}
                      <div className="form">

                        <div className="form_group">
                          <label htmlFor="form">From</label>

                          <div className="canvas">
                            <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'City, Country...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const { description } = suggestion;

                                      const style = suggestion.active
                                        ? { backgroundColor: '#f2f3f4', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          className="input-suggestion"
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          <i className="bi bi-geo-alt-fill"></i> <span>{description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {!selectAdress && <p style={{ color: "red" }}>Please select one of the suggested options</p>}
                          </div>

                        </div>
                        <div className="buttons">
                          <button type="button"
                            className={`btn btn-edit ${!skipModalState ? "previousStep" : ""}`}
                            onClick={skipModalState
                              ? () => {
                                document.querySelector(".step-5").classList.remove("show");
                                document.querySelector(".step-1").classList.add("show");
                              }
                              : undefined
                            }

                          >Back</button>
                          {!spinner ?
                            <button
                              className="btn"
                              type={`${!skipModalState ? "submit" : "button"}`}
                              disabled={!selectAdress}
                              onClick={() => { skipModalState ? setskipModalOpen(true) : setskipModalOpen(false) }}
                            // data-bs-toggle={skipModalState ? "modal" : ""}
                            // data-bs-target={skipModalState ? "#skipModal" : ""}
                            >Get started</button>
                            :
                            <button className="btn">
                              <span className="loader"></span>
                            </button>
                          }

                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </form>


        // {/* /////////skip modal/////////// */}

        : <div
          className="modal fade bph-modal show "
          id="skipModal"
          tabIndex={-1}
          aria-labelledby="skipModal"
          aria-hidden="true"
          style={{ display: 'block' }}
        >
          <div className="modal-dialog modal-dialog-centered modalAnimation">
            <div className="modal-content">
              <div className="modal-body">
                <div className="onboarding_modal skip_modal">
                  <div className="modal_inner">
                    <button
                      type="button"
                      className="btn-close skipclose"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={JoinNowCloseHandler}
                    ></button>
                    {/* <!-- logo --> */}
                    <div className="modal_logo">
                      <img src="./assets/img/logo/modal-logo.png" />
                    </div>

                    {/* <!-- content --> */}
                    <div className="modal_content">
                      <div className="modal_title">
                        <h4 className="title">Hi!</h4>
                      </div>

                      {/* <!-- form --> */}
                      <div className="form">
                        <div className="form_title">
                          <p>
                            Since you’re just here to support our photographers,
                            come right in and make yourself at home!
                          </p>
                        </div>

                        <div className="buttons">
                          {!spinner ?
                            <Link className="btn" to="#" onClick={JoinHandler}> Enter</Link>
                            :
                            <Link className="btn">
                              <span className="loader"></span>
                            </Link>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}

      {AddLibrary("./assets/js/magnific-popup.min.js")}
      {AddLibrary("./assets/js/owl.carousel.min.js")}
      {AddLibrary("./assets/js/main.js")}
    </>
  );
};

export default JoinNowModal;
