import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { decryptUserData } from '../../utils/encryptedData'
import { useSelector } from "react-redux";
import { photoUploadQ3List } from "../../utils/enums/listing";
import exifr from 'exifr'
import { GenresList } from "../../utils/enums/listing";
import amplitude from 'amplitude-js';
import { DayList } from "../../utils/enums/listing";
import { checkImageLimit } from "../../utils/API/GalleryApi";
import { RemainderApi } from "../../utils/API/RemainderApi";
import useCloseModalHandler from "../../Hooks/useCloseModal";
import PayWallModal from "./PayWallModal";




const PhotoUploadModal = () => {
  const [image, setimage] = useState('')
  const [spinner, setspinner] = useState(false)
  const [imagePath, setimagePath] = useState('')
  const [ERROR, setERROR] = useState('')
  const [uploaded, setUploaded] = useState('');
  const [OnephotoLimitScreen, setOnephotoLimitScreen] = useState(false);
  const [Q1, setQ1] = useState(true)
  const [Q2, setQ2] = useState(false)
  const [Q3, setQ3] = useState(false)
  const [Q4, setQ4] = useState(false)
  const [textareaValue, settextareaValue] = useState('')
  const [EnteredQ3, setEnteredQ3] = useState([]);
  const [EnteredQ2, setEnteredQ2] = useState([]);
  const [Q4Value, setQ4Value] = useState([]);
  const [Metadata, setMetadata] = useState('')
  const [GalleryModalPhoto, setGalleryModalPhoto] = useState('')
  const [AskingAddPhotoInToQueueScreen, setAskingAddPhotoInToQueueScreen] = useState(false)
  const [QuestionsAndNotificationScreen, setQuestionsAndNotificationScreen] = useState(false)
  const [AskingForReminderScreen, setAskingForReminderScreen] = useState(false)
  const [NotReminderScreen, setNotReminderScreen] = useState(false)
  const [ConsiderReminderScreen, setConsiderReminderScreen] = useState(false)
  const [AlreadyPhotoInQueueScreen, setAlreadyPhotoInQueueScreen] = useState(false)
  const [PhotoUploadCompleteScreen, setPhotoUploadCompleteScreen] = useState(false)
  const [QueuedConfirmationScreen, setQueuedConfirmationScreen] = useState(false)
  const [AlreadyQueuedConfirmatedState, setAlreadyQueuedConfirmatedState] = useState(false)
  const [CheckUserCanUploadPhoto, setCheckUserCanUploadPhoto] = useState(true);
  const [NonPaidUserQueuedLimitExceed, setNonPaidUserQueuedLimitExceed] = useState(false);
  const [nextMonday, setNextMonday] = useState(null);



  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
  const MAX_IMAGE_EDGE = 6500;
  const ALLOWED_FORMATS = ['image/jpeg', 'image/png'];


  // <-------------------------------------------->
  let navigate = useNavigate();

  let dispatch = useDispatch()

  let UserPaymentStatus = useSelector(state => state.PayWall.UserPaymentStatus)
  let StateAfterUploadPhoto = useSelector(state => state.Modal.StateAfterUploadPhoto)
  let PhotoType = useSelector(state => state.Modal.PhotoUploadType)
  let IsQueued = useSelector(state => state.Modal.isQueued)
  let GalleryModalPhotoObject = useSelector(state => state.Modal.GalleryModalPhotoObject)
  let QueuePhotoObjectSelectedFromQueuePage = useSelector(state => state.Modal.QueuePhotoObjectSelectedFromQueuePage);



  // <-------------------------------------------->
  useEffect(() => {
    if (GalleryModalPhotoObject) {
      CheckingImageLimit()

      setGalleryModalPhoto(GalleryModalPhotoObject)
      setimagePath(`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${GalleryModalPhotoObject.compressedImageId}`)
      // setQ1(true)
      dispatch(ModalAction.setGalleryModalPhotoObject(''))
    }

    if (QueuePhotoObjectSelectedFromQueuePage.selectedImage) {
      CheckingImageLimit()
      setMetadata(QueuePhotoObjectSelectedFromQueuePage.metadataString)
      setimage(QueuePhotoObjectSelectedFromQueuePage.selectedImage)
      setimagePath(QueuePhotoObjectSelectedFromQueuePage.imagePath)
      dispatch(ModalAction.setQueuePhotoObjectSelectedFromQueuePage(''))
      // setisQueuedPage(true)
    }
  }, [])
  // <-------------------------------------------->

  let GalleryPhotoModalHandler = () => {
    dispatch(ModalAction.setUploadPhotoFromGalleryModalState(true))
    dispatch(ModalAction.setPhotoUploadModalState(false))
  }

  // <-------------------------------------------->



  function getNextMondayDate(weekDay) {
    let nextMondayDate;

    if (IsQueued.firstWeekCreatedAtDate) {
      let firstUpload = new Date(IsQueued.firstWeekCreatedAtDate);
      let dayOfWeek = firstUpload.getUTCDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

      // Calculate the start of the first week (the Monday of that week)
      let startOfFirstWeek = new Date(firstUpload);

      // If the first upload is on a Sunday, adjust to the previous Monday
      if (dayOfWeek === 0) {
        startOfFirstWeek.setDate(firstUpload.getDate() - 6);
      } else {
        startOfFirstWeek.setDate(firstUpload.getDate() - dayOfWeek + 1);
      }

      // Calculate the Monday for the desired week
      nextMondayDate = new Date(startOfFirstWeek.getTime() + ((weekDay - 1) * 7 * 24 * 60 * 60 * 1000));
    } else {

      let today = new Date(); // Current date and time
      let dayOfWeek = today.getUTCDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
      let daysUntilNextMonday = (8 - dayOfWeek) % 7; // Calculating days until next Monday

      if (daysUntilNextMonday === 0) {
        daysUntilNextMonday = 7; // If today is Monday, get next Monday
      }

      nextMondayDate = new Date(today.getTime() + (daysUntilNextMonday * 24 * 60 * 60 * 1000));

    }

    return nextMondayDate;
  }



  // Example usage in your effect
  useEffect(() => {

    if (IsQueued.weekDay) {
      setNextMonday(getNextMondayDate(Number(IsQueued.weekDay)));
    }
  }, [IsQueued]);


  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  let loginUserid = decryptedUserData.userid


  // <-------------------------------------------->

  let maxLength = 240
  let textareaChangeHandler = (e) => {
    let value = e.target.value;
    const trimmedValue = value.slice(0, maxLength);
    settextareaValue(trimmedValue)


  }
  const remainingChars = maxLength - textareaValue.length;

  // <-------------------------------------------->
  let uploadinputClickhandler = () => {
    document.querySelector('.profile-upload-input').click()

  }
  // <-------------------------------------------->
  let Q2Changehandler = (e) => {
    let value = e.target.value.toString();
    let checked = e.target.checked
    if (checked) {
      if (EnteredQ2.length < 1) {
        setEnteredQ2([...EnteredQ2, value]);
      } else {
        e.target.checked = false;
      }
    } else {
      setEnteredQ2(EnteredQ2.filter((e) => (e !== value)))
    }
  }

  // <-------------------------------------------->
  let Q3Changehandler = (e) => {
    let value = e.target.value.toString();
    let checked = e.target.checked;

    if (EnteredQ3[0] == 'I am not looking for feedback') {
      e.target.checked = false;
      if (e.target.value == 'I am not looking for feedback') {
        setEnteredQ3([])
      }
      return
    }
    if (checked) {
      // Check if the user can select more checkboxes
      if (value == 'I am not looking for feedback' && checked) {
        let checBOx = document.querySelectorAll(".uploadModalCheckBox")
        for (let i = 0; i < checBOx.length; i++) {
          if (value == 'I am not looking for feedback') {
            checBOx[i].checked = false
            e.target.checked = true;
            setEnteredQ3([value])
          }
        }


      } else {
        if (EnteredQ3.length <= 2) {
          setEnteredQ3([...EnteredQ3, value]);
        } else {
          // Prevent further additions if the limit is reached
          e.target.checked = false;
        }
      }

    } else {
      setEnteredQ3(EnteredQ3.filter((item) => item !== value));
    }
  };



  let Q4Changehandler = (e) => {
    let value = e.target.value.toString();
    let checked = e.target.checked
    if (checked) {
      if (Q4Value.length < 1) {
        setQ4Value([...Q4Value, value]);
      } else {
        e.target.checked = false;
      }
    } else {
      setQ4Value(Q4Value.filter((e) => (e !== value)))
    }
  }





  // <-------------------------->
  let differentPhotoHandler = () => {
    setimagePath("")
    settextareaValue('')
    setEnteredQ3([]);
    setEnteredQ2([]);
    setQ4Value([]);
    setQ1(true)
    setQ2(false)
    setQ3(false)
    setQ4(false)
  }
  // <-------------------------->
  let backBTNHandler = () => {
    setOnephotoLimitScreen(false)
    setQuestionsAndNotificationScreen(false)
  }
  // <-------------------------->

  let OnephotoLimitScreenHandler = () => {
    setOnephotoLimitScreen(true)
    setQuestionsAndNotificationScreen(true)
  }

  // <-------------------------->
  let YesBtnQueueScreenHandler = () => {
    setAskingAddPhotoInToQueueScreen(false)
    setQuestionsAndNotificationScreen(false)
    // setisQueued(true)
    if (UserPaymentStatus) {
      dispatch(ModalAction.setisQueued({ queue: false }))
    } else {

      dispatch(ModalAction.setisQueued({ queue: true, weekDay: 1, }))
    }
  }
  let NoBtnQueueScreenHandler = () => {
    setAskingAddPhotoInToQueueScreen(false)
    setAskingForReminderScreen(true)
  }
  // <-------------------------->
  let YesBtnReminderHandler = () => {
    setAskingForReminderScreen(false)
    setConsiderReminderScreen(true)
    setAskingAddPhotoInToQueueScreen(false)
    setAlreadyPhotoInQueueScreen(false)
    RemainderFunction()
    amplitude.getInstance().logEvent('Request Hunt Reminder');
  }
  let NoBtnReminderHandler = () => {
    setAskingForReminderScreen(false)
    setNotReminderScreen(true)
    setAskingAddPhotoInToQueueScreen(false)
    setAlreadyPhotoInQueueScreen(false)
  }

  // <-------------------------->
  let GoToProfileHandler = () => {
    dispatch(ModalAction.setPhotoUploadModalState(false))
    navigate('/queue', { replace: true })
    window.scrollTo(0, 0)
  }
  // <-------------------------->
  let YesBtnCompleteUploadScreenHandler = () => {
    setPhotoUploadCompleteScreen(false)
    setAlreadyQueuedConfirmatedState(true)
    // setisQueued(true)
    if (UserPaymentStatus) {
      dispatch(ModalAction.setisQueued({ queue: false }))
    } else {

      dispatch(ModalAction.setisQueued({ queue: true, weekDay: 1, }))
    }
    settextareaValue('')
    setimagePath("")
    setEnteredQ3([]);
    setEnteredQ2([]);
    setQ1(true)
    setQ2(false)
    setQ3(false)
    setQuestionsAndNotificationScreen(false)
  }
  let NoBtnCompleteUploadScreenHandler = () => {
    setQ2(false)
    setQ3(false)
    setPhotoUploadCompleteScreen(false)
    setAskingForReminderScreen(true)
  }
  // <-------------------------->

  let ModalHandler = async () => {
    setAlreadyPhotoInQueueScreen(true);
    setQ3(false)
    setQ1(false)
    setimagePath("")
    setQuestionsAndNotificationScreen(true)

    try {
      let headers = {
        'Authorization': `Bearer ${usertoken}`
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/getQueuedPhotoCount`, {}, { headers });


    } catch (error) {
      console.log(error, "error")
    }
  }



  // <-------------------------->
  const imgHandler = (e) => {
    const selectedImage = e.target.files[0];

    // if (!AlreadyQueuedConfirmatedState) {
    //   CheckingImageLimit()
    // }

    setERROR("")
    // Validate image format
    if (!ALLOWED_FORMATS.includes(selectedImage.type)) {
      setERROR('Please upload only JPG or PNG files');
      return;
    }





    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);
    img.onload = async () => {
      await img.decode(); // Load the image to get its dimensions

      const { width, height } = img;



      // Validate file size
      if (selectedImage.size > MAX_FILE_SIZE) {
        setERROR('Please ensure that your file is less than 50MB.');
        return;
      } else if (width > MAX_IMAGE_EDGE || height > MAX_IMAGE_EDGE) {
        setERROR('Please ensure the edges of your photo are less than 6500 pixels.');
        return;
      }

      else {
        CheckingImageLimit()
        // Extract metadata using exifr
        try {
          const metadata = await exifr.parse(selectedImage, {
            tiff: true,    // Enable TIFF parsing for extended metadata
            xmp: true,     // Enable XMP parsing for extended metadata
          });

          if (metadata) {
            const metadataObject = {
              cameraName: metadata.Make,
              focalLength: metadata.FocalLength,
              lens: metadata.LensModel || metadata.Lens,
              aperture: metadata.FNumber,
              iso: metadata.ISO,
              shutterSpeed: metadata.ExposureTime,
              dateTaken: metadata.DateTimeOriginal,
            };

            const metadataString = JSON.stringify(metadataObject);

            setMetadata(metadataString)

          } else {

          }
        } catch (error) {
          console.error('Error extracting metadata:', error);
        }

        setimage(selectedImage);

        const reader = new FileReader();
        reader.onload = () => {
          setimagePath(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      };
    }

  };



  const CheckingImageLimit = async (e) => {
    setCheckUserCanUploadPhoto(false);
    try {
      const payload = {
        "isQueued": IsQueued.queue,
      };


      const response = await checkImageLimit(payload, usertoken,);

      let responseValue = response.data.data;

      if (responseValue.message === "Photo limit exceed") {
        setAskingAddPhotoInToQueueScreen(true);
        setQuestionsAndNotificationScreen(true);
      } else if (responseValue.message === "Photo is already in a queue") {
        setAlreadyPhotoInQueueScreen(true);
        setQuestionsAndNotificationScreen(true);
      } else if (responseValue.message === "Four Photos already in a queue") {
        setAlreadyPhotoInQueueScreen(true);
        setQuestionsAndNotificationScreen(true);
        // setisQueuedPage(true);
      } else if (responseValue.message == "Non Paid User Queued Photo Limit Exceed") {

        setNonPaidUserQueuedLimitExceed(true)
      }
      if (responseValue.weekDay || responseValue.createdAt) {
        setNextMonday(getNextMondayDate(Number(responseValue.weekDay)));
        dispatch(ModalAction.setisQueued({ queue: true, weekDay: responseValue.weekDay, firstWeekCreatedAtDate: responseValue.createdAt }))
      }
      setCheckUserCanUploadPhoto(true);



    } catch (error) {
      console.log(error);
    }
  };


  const RemainderFunction = async (PaidUser) => {
    if (PaidUser) {
      YesBtnReminderHandler()
    }
    try {
      const response = await RemainderApi(usertoken, PaidUser);

    } catch (error) {
      console.error('Failed to send photo reminder:', error);
    }
  };




  const uploadHandler = async (e) => {

    setERROR("")
    // Mapping of days to the number of days to add
    const daysToAdd = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6
    };

    // Function to adjust nextMonday based on Q4Value
    const getAdjustedNextMonday = (nextMonday, day) => {
      const days = daysToAdd[day] || 0;
      const adjustedDate = new Date(nextMonday);
      adjustedDate.setDate(nextMonday.getDate() + days);
      return adjustedDate;
    };

    let timestamp;
    let filename;
    let formData;
    if (image) {

      timestamp = new Date().getTime();
      filename = `image_${timestamp}.jpg`;
      formData = new FormData()
      formData.append('photoUrl', image, filename);
      formData.append('description', textareaValue.trim())
      formData.append('genres_list', EnteredQ2.join(','))
      formData.append('feedback_type', EnteredQ3.join(','))
      formData.append('metadata', Metadata)
      formData.append('isQueued', IsQueued?.queue)
      // formData.append('photoType', PhotoType)

      if (IsQueued?.weekDay) {
        const adjustedNextMonday = getAdjustedNextMonday(nextMonday, Q4Value);

        formData.append('weekDay', IsQueued.weekDay);
        formData.append('uploadAt', adjustedNextMonday.toUTCString());
        formData.append('day', Q4Value.length > 0 ? Q4Value : "Monday");
      }
    }

    if (GalleryModalPhoto.photoID) {
      formData = {
        'description': textareaValue.trim(),
        'genres_list': EnteredQ2.join(','),
        'feedback_type': EnteredQ3.join(','),
        'isQueued': IsQueued?.queue ? "true" : "false",
        'photoType': "hunted_photo",
        'photoId': GalleryModalPhoto.photoID,
      }
      if (IsQueued?.weekDay) {
        const adjustedNextMonday = getAdjustedNextMonday(nextMonday, Q4Value);
        formData['weekDay'] = IsQueued.weekDay;
        formData['uploadAt'] = adjustedNextMonday.toUTCString();
        formData['day'] = Q4Value.length > 0 ? Q4Value.join(',') : "Monday";
      }

    }


    setspinner(true)
    try {


      let headers = {
        'Authorization': `Bearer ${usertoken}`
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/add`, formData, {
        headers,
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        }
      });




      if (response.data.data.isQueued == false) {
        setPhotoUploadCompleteScreen(true)
        setQuestionsAndNotificationScreen(true)
        amplitude.getInstance().setUserId(loginUserid);
        amplitude.getInstance().logEvent('Photo Upload', { UserID: loginUserid });
      } else {
        setQueuedConfirmationScreen(true)
        setQuestionsAndNotificationScreen(true)
        amplitude.getInstance().logEvent('Photo Queued', { UserID: loginUserid });
      }

      dispatch(ModalAction.setStateAfterUploadPhoto(!StateAfterUploadPhoto))
      setspinner(false)

    } catch (error) {

      setERROR(error.response.data.message)
    }


  }


  const closeModal = () => {
    dispatch(ModalAction.setPhotoUploadModalState(false))
  }
  const modalRef = useCloseModalHandler(closeModal);

  let PayWallModalOpenHalder = () => {
    closeModal()
    navigate('/14dfspecial', { replace: true })
  }
  return (
    <>
      <div className="backdrop" />
      {/* Photo Upload */}
      <div className="modal fade bph-modal show" id="photoUploadModal" tabIndex={-1} aria-labelledby="photoUploadModal" aria-hidden="true" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered modalAnimation" >
          <div className="modal-content">
            <div className="modal-body">
              <div className={`onboarding_modal photoUpload_modal photoUpload ${(Q3 || Q2) && (!QueuedConfirmationScreen && !PhotoUploadCompleteScreen) ? "feedback" : ""}`}>
                <div className="modal_inner" ref={modalRef}>
                  <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"
                    onClick={closeModal} />
                  {/* logo */}
                  <div className="modal_logo d-none d-md-inline-flex">
                    <img src="./assets/img/logo/modal-logo.png" />
                  </div>
                  {/* content */}
                  {!QuestionsAndNotificationScreen ? <div className="modal_content"
                  // style={Q3 ?{padding:" 60px 10px 45px 15px"}:{}}
                  >
                    {CheckUserCanUploadPhoto && <div className="modal_title">
                      <h2 className="title"><img src="./assets/img/icons/upload.svg" alt="img" />Upload</h2>
                      {!Q3 && !Q2 && <p className="subtitle">{imagePath ? (!Q4 ? "Great! Please share something about this photo to help members engage with it." : "And, last thing... What day of the week would you like this photo to automatically post?") : (
                        !IsQueued?.queue ?
                          <>
                            Upload your best photo for this week!{" "}
                            <a style={{ textDecoration: "underline" }} onClick={OnephotoLimitScreenHandler}>One photo each week,</a>{" "}
                            to be hunted by your peers.
                          </>
                          :
                          "Load your photo to the queue to automatically post to a future Photo Hunt."
                      )}</p>}
                      {Q2 && <p className="subtitle">So that we can put your photo in the right place... </p>}
                      {Q3 && <p className="subtitle">OK, and what type of feedback could be helpful to you on this photo?</p>}
                    </div>}
                    {/* upload photo */}
                    <div className="file-upload">
                      {ERROR && <p className="eerror" style={{ textAlign: 'center' }}>{ERROR} </p>}
                      {/* <p className="subtitle profile-upload-before-title">Then get your photo upvoted to the top
                      of the Leaderboard.</p>
                    <p className="subtitle profile-upload-after-title">Great! Please share something about this photo to help
                      members engage with it.</p> */}

                      {!imagePath && <div className="profile-image-upload-wrap">
                        <input className="profile-upload-input" onChange={imgHandler} type="file" />
                        <div className="drag-text" style={{ maxWidth: "390px", width: "100%" }}>
                          <h4 className="title">Drag and drop your photo here</h4>
                          <span className="or">or</span>

                          {/*

                           */}

                          <div className="buttons" style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'center', gap: "12px" }}>
                            <button className="file-upload-btn btn" type="button" onClick={uploadinputClickhandler}
                              // style={{ maxWidth: PhotoType == "hunted_photo" ? " " : "188px" }}
                              style={{ maxWidth: "188px" }}
                            >
                              Upload from device
                            </button>
                            {
                              // PhotoType == "gallery" && 
                              < button className="file-upload-btn btn" type="button" style={{ maxWidth: "188px" }} onClick={GalleryPhotoModalHandler}>
                                Upload from Gallery
                              </button>}
                          </div>



                          <span className="support_note">
                            Max. 6500 x 6500 px., 50 MB<br />
                            JPG or PNG format only. No Borders.</span>
                        </div>
                      </div>}

                      {imagePath && Q1 &&
                        <>

                          {CheckUserCanUploadPhoto ? <div className="profile-upload-content">

                            <div className="drag_image">
                              <div className="drag_image_inner">
                                <img className="profile-upload-image" id="upload-img" src={imagePath} alt="your image" />
                              </div>
                            </div>
                            <div className="question_part">
                              <p>Question <span>1</span>{`${IsQueued.queue && UserPaymentStatus ? "/4" : "/3"}`}</p>
                            </div>
                            <div className="description">
                              <h6 className="title" style={{ fontSize: "16px", fontWeight: '500' }}>Tell us about your photo.</h6>
                              <textarea name="description" id="description" placeholder="Tell club members what we are looking at here, or something else about this photo" value={textareaValue} onChange={textareaChangeHandler} />
                              <span className="characters_limit" > ({remainingChars} characters) </span>
                            </div>
                            <div className="profile-image-title-wrap">
                              <button type="button" className="btn " onClick={() => {
                                setQ1(false)
                                setQ2(true)
                              }}>Continue</button>
                              <label className="remove-profile-image" onClick={differentPhotoHandler}>Select a different photo</label>
                            </div>
                          </div>
                            :
                            <div className="d-flex justify-content-center flex-column align-items-center gap-3" style={{ margin: '170px auto' }}>
                              <span className="FollowModalImgLoader" style={{ display: "block" }}></span>
                              <p>Please Wait</p>
                            </div>}
                        </>
                      }

                      {Q2 && <div className="file-upload">


                        <div className="profile-upload-content">

                          <div className="drag_image">
                            <div className="drag_image_inner">
                              <img className="profile-upload-image" id="upload-img" src={imagePath} alt="your image" />
                            </div>
                          </div>

                          <div className="question_part">
                            <p>Question <span>2</span>{`${IsQueued.queue && UserPaymentStatus ? "/4" : "/3"}`}</p>
                          </div>

                          <div className="feedbacks">
                            <h6 className="title">What genre is this photo?</h6>

                            <div className="row g-md-3">

                              {GenresList.map((item, index) => (
                                <div className="col-xl-4 col-6" key={index}>
                                  <div className="form_group checkbox">
                                    <input className="uploadModalCheckBox" type="checkbox" name={item.id} value={item.value} id={item.id} onChange={Q2Changehandler} />
                                    <label htmlFor={item.id}>{item.value}</label>
                                  </div>
                                </div>
                              ))
                              }
                            </div>
                          </div>
                          <div className="profile-image-title-wrap">
                            <button type="button" className="btn " onClick={() => {
                              setQ2(false)
                              setQ3(true)
                            }}>Continue</button>
                            <label className="remove-profile-image" onClick={differentPhotoHandler}>Select a different photo</label>
                          </div>
                        </div>

                      </div>}

                      {Q3 && <div className="file-upload">


                        <div className="profile-upload-content">

                          <div className="drag_image">
                            <div className="drag_image_inner">
                              <img className="profile-upload-image" id="upload-img" src={imagePath} alt="your image" />
                            </div>
                          </div>

                          <div className="question_part">
                            <p>Question <span>3</span>{`${IsQueued.queue && UserPaymentStatus ? "/4" : "/3"}`}</p>
                          </div>

                          <div className="feedbacks">
                            <h6 className="title">What type of feedback are you looking for (3 max.)?</h6>

                            <div className="row g-md-3">

                              {photoUploadQ3List.map((item, index) => (
                                <div className="col-xl-4 col-6" key={index}>
                                  <div className="form_group checkbox">
                                    <input className="uploadModalCheckBox" type="checkbox" name={item.value} value={item.value} id={item.value} onChange={Q3Changehandler} />
                                    <label htmlFor={item.value}>{item.value}</label>
                                  </div>
                                </div>
                              ))
                              }
                            </div>

                          </div>

                          <div className="profile-image-title-wrap">
                            {!spinner ?
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <button type="button" onClick={IsQueued?.queue && UserPaymentStatus ? () => {
                                  setQ4(true)
                                  setQ3(false)
                                  setQ2(false)
                                  setQ1(false)
                                } : (!NonPaidUserQueuedLimitExceed ? uploadHandler : ModalHandler)} className=" btn" > Continue</button>
                                <label className="remove-profile-image" style={{ marginTop: '10px' }} onClick={differentPhotoHandler}>Select a different photo</label>
                              </div>
                              :
                              <div className="progress" style={{ width: '100%' }}>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${uploaded}%`, color: 'black' }} aria-valuenow={uploaded} aria-valuemin={0} aria-valuemax={100}>{`${uploaded}%`}</div>
                              </div>

                            }

                          </div>
                        </div>

                      </div>}

                      {Q4 && UserPaymentStatus && <div className="edit_field_content">
                        <img src={imagePath} alt='img' />
                        <div className="desc">Question 4/4</div>
                        <div className="post_date">It will post at 12:00am EST on the day you pick.</div>
                        <div className="row g-md-3">

                          {DayList.map((item, index) => (
                            <div className="col-6" key={index}>
                              <div className="form_group checkbox">
                                <input
                                  type="checkbox"
                                  name={item.value}
                                  id={item.id}
                                  value={item.value}
                                  // checked={selectedDay === item.value}
                                  onChange={Q4Changehandler}
                                />
                                <label htmlFor={item.id}>{item.lable}</label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <div className="profile-image-title-wrap">
                          {!spinner ?
                            <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                              <button type="button" onClick={uploadHandler} className=" btn" style={{ maxWidth: "180px" }} > Continue</button>
                              <label className="remove-profile-image" style={{ marginTop: '10px' }} onClick={differentPhotoHandler}>Select a different photo</label>
                            </div>
                            :
                            <div className="progress" style={{ width: '100%', marginTop: "15px" }}>
                              <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${uploaded}%`, color: 'black' }} aria-valuenow={uploaded} aria-valuemin={0} aria-valuemax={100}>{`${uploaded}%`}</div>
                            </div>

                          }

                        </div>
                      </div>
                      }



                    </div>
                  </div>

                    :
                    <div className="modal_content">
                      {OnephotoLimitScreen && <div className="modal_title" style={{ maxWidth: '100%' }}>

                        <h3 className="title">One photo for each weekly hunt.</h3>
                        <p className="subtitle" style={{ maxWidth: "100%" }}>Everyone gets one photo per weekly photo hunt. Each week starts on Monday at 12:00am EST (New York City time).</p>


                        <p style={{ marginTop: "15px", maxWidth: "100%" }} className="subtitle">You can also add photos to your <Link to={'/profile?tab=gallery'} style={{ color: "blue" }} onClick={() => { dispatch(ModalAction.setPhotoUploadModalState(false)) }}>profile’s gallery</Link>  if you’d like to share your style with Club Members.</p>

                        <button style={{ marginTop: "20px", padding: '10px 40px ' }} className="btn" onClick={backBTNHandler}>Back</button>
                      </div>}

                      {AskingAddPhotoInToQueueScreen &&
                        <div className="modal_title" style={{ maxWidth: '100%' }}>
                          <h3 className="title">Just one photo for each weekly hunt.</h3>
                          <p className="subtitle" style={{ marginBottom: '0px' }}>Oh! It looks like you’ve already posted a photo to this week’s hunt.</p>
                          <div className="drag_image_inner">
                            <img className="profile-upload-image" id="upload-img" src={imagePath} alt="your image" />
                          </div>

                          <p style={{ marginTop: "15px" }} className="subtitle">Would you like us to queue this photo for you so it automatically posts to next week's Big Photo Hunt?</p>

                          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                            <button className="btn PhotoUploadYesBtn" onClick={YesBtnQueueScreenHandler}>Yes, Please</button>
                            <button className="btn PhotoUploadNoBtn" onClick={NoBtnQueueScreenHandler}>No, Thanks</button>
                          </div>


                        </div>
                      }

                      {AskingForReminderScreen && <div className="modal_title" style={{ maxWidth: '100%', padding: "95px 0px" }}>
                        <h3 className="title">OK. We won't touch it.</h3>
                        <p className="subtitle" style={{ maxWidth: '100%' }}>Would you like us to send you an email reminder to let you know when you can submit your next photo?</p>


                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                          <button className="btn PhotoUploadYesBtn" onClick={YesBtnReminderHandler}>Yes, Please</button>
                          <button className="btn PhotoUploadNoBtn" onClick={NoBtnReminderHandler}>No, Thanks</button>
                        </div>
                      </div>
                      }

                      {NotReminderScreen && <div className="modal_title" style={{ maxWidth: '100%', padding: "60px 0px" }}>
                        <h3 className="title">OK. We won't remind you.</h3>
                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>Enjoy your Big Photo Hunting in the meantime!</p>

                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>Reminder: Each weekly Big Photo Hunt starts on Monday at 12:00am NYC time. You can post your photo to next week's hunt any time after that. Or, to queue up multiple photos, here’s a <a style={{ color: 'blue' }} onClick={PayWallModalOpenHalder}>free 14 day trial</a> to our paid membership.</p>

                        <button onClick={GoToProfileHandler} style={{ marginTop: "20px", }} className="btn PhotoUploadYesBtn" >Go to My Queue</button>
                      </div>}

                      {ConsiderReminderScreen && <div className="modal_title" style={{ maxWidth: '100%', padding: "60px 0px" }}>
                        <h3 className="title">Consider yourself reminded.</h3>
                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>We'll send you an email at the start of next week’s Hunt (Monday, 12:00am NYC time) to remind you to queue your next photo.</p>

                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>{!UserPaymentStatus ? (
                          <>
                            (BTW - If you want to explore queuing up multiple photos, here’s a <a style={{ color: 'blue' }} onClick={PayWallModalOpenHalder}>free 14 day trial</a> to our paid membership.)
                          </>
                        ) : (
                          "(This depends on when your next photo is scheduled in your Queue Manager to be Hunted. Either way, you'll hear from us shortly after it is.)‌"
                        )}

                        </p>

                        <button onClick={GoToProfileHandler} style={{ marginTop: "20px", }} className="btn PhotoUploadYesBtn" >Go to My Queue</button>
                      </div>}

                      {AlreadyPhotoInQueueScreen && <div className="modal_title" style={{ maxWidth: '100%', padding: "40px 0px" }}>

                        <h3 className="title">{`${!UserPaymentStatus ?
                          "Your photos are already being hunted."
                          : "Oops."
                          }`}</h3>
                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>
                          {`${!UserPaymentStatus ?
                            (NonPaidUserQueuedLimitExceed ?
                              `It looks like you’ve got a photo in this week’s Hunt already and you’ve already used up your 2 free photo queues. If you want to give unlimited queuing a test, ` :
                              "It looks like you have a photo in this week's hunt and another one queued to post for next week. You can only load this photo once the next one posts.")
                            : "It looks like you have a photo in this week’s Hunt and have photos queued up for the next four weeks. That's the limit."}`}
                          {!UserPaymentStatus && NonPaidUserQueuedLimitExceed && (
                            <a style={{ color: 'blue' }} onClick={PayWallModalOpenHalder}>free 14 day trial</a>
                          )}
                          {` to our paid membership.`}
                        </p>

                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>{`${!UserPaymentStatus ? (NonPaidUserQueuedLimitExceed ? "In the meantime, we’ll hold this photo for you for 12 hours." : "Would you like us to send you a reminder email when you can add your next photo to the weekly queue?") : "You can manage and remove photos in your queue."}`}

                        </p>


                        {!UserPaymentStatus ?
                          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                            {NonPaidUserQueuedLimitExceed ?
                              <button className="btn PhotoUploadYesBtn" onClick={PayWallModalOpenHalder}>Tell Me More</button>
                              :
                              <>
                                <button className="btn PhotoUploadYesBtn" onClick={YesBtnReminderHandler}>Yes, Please</button>
                                <button className="btn PhotoUploadNoBtn" onClick={NoBtnReminderHandler}>No, Thanks</button>
                              </>}
                          </div> :
                          <div style={{ display: 'flex', flexDirection: "column", gap: '10px', display: 'inline-flex' }}>
                            <button className="btn " style={{ padding: "10px 60px", marginTop: '20px' }} onClick={() => {
                              navigate('/queue', { replace: true })
                              dispatch(ModalAction.setPhotoUploadModalState(false))
                            }}>Ok</button>

                            <a onClick={() => { RemainderFunction(true) }} style={{ color: 'blue', marginTop: "20px", fontSize: '13px' }}>Remind me when I can</a>
                          </div>

                        }

                      </div>}

                      {PhotoUploadCompleteScreen && <div className="modal_title" style={{ maxWidth: '100%', padding: "50px 0px" }}>
                        <h3 className="title">Great! Your photo's posted.</h3>
                        <h4 className="title" style={{ margin: "20px auto", maxWidth: '85%' }}>Do you want to queue your next photo while you're here?</h4>

                        <p className="subtitle" style={{ margin: "20px auto" }}>We can load up your next photo to automatically post to next week's Big Photo Hunt for you.</p>


                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                          <button className="btn PhotoUploadYesBtn" onClick={YesBtnCompleteUploadScreenHandler}>Yes, Please</button>
                          <button className="btn PhotoUploadNoBtn" onClick={NoBtnCompleteUploadScreenHandler}>No, Thanks</button>
                        </div>


                      </div>}

                      {QueuedConfirmationScreen && <div className="modal_title" style={{ maxWidth: '100%', padding: "60px 0px" }}>
                        <h3 className="title">Your photo is queued</h3>
                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>{`${UserPaymentStatus ? "You can manage it on your Queue Manger Page " : "Your photo has been added to the queue manager. It is set to post on Monday at 12:05 am EST (NYC time zone). We'll send you an email to notify you when this photo posts."}`}
                        </p>
                        <p className="subtitle" style={{ margin: "20px auto", maxWidth: '100%' }}>
                          {`${UserPaymentStatus ?
                            "We’ll send you an email to notify you when this photo posts." :
                            "BTW - This feature is a paid feature but the 1st two are free - on us. "}`}
                          {!UserPaymentStatus && (
                            <>
                              Here’s a <a style={{ color: 'blue' }} onClick={PayWallModalOpenHalder}>free 14 day trial</a> if you want to try the full queue manager.
                            </>
                          )}
                        </p>




                        <button onClick={() => {
                          navigate('/queue', { replace: true });
                          dispatch(ModalAction.setPhotoUploadModalState(false));
                        }} className="btn PhotoUploadYesBtn" >{UserPaymentStatus ? "Show Me" : "OK"}</button>

                      </div>}
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* ! Photo Upload */}






    </>
  )
}

export default PhotoUploadModal