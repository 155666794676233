import { useEffect, useRef } from 'react';

const useCloseModalHandler = (onClose) => {
    const modalRef = useRef(null);

    useEffect(() => {


        const handleOutsideClick = (event) => {

            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    return modalRef;
};

export default useCloseModalHandler;
